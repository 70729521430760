<template>
  <div>
    <PDialog :header="'Editeur de mise en stage/formation'" :modal="true" :visible.sync="display" :contentStyle="{overflow: 'visible'}" :containerStyle="{width: '40vw'}">
     <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
        <div class="card mt-4 border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()" >
              <template>
                <div class="row">
                  <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select id="exprimePar" name="exprimePar" option-label="label" option-value="value" v-model="editableItem.exprimePar" :options="typeExprimeurs" class="form-select">
                        </c-select>
                        <label for="exprimePar">Exprimeur</label>
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12 field" v-if="editableItem.exprimePar && editableItem.exprimePar.value != 'DEMANDEUR'">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select id="assiduite" name="assiduite" option-label="libelle" option-value="id" v-model="editableItem.assiduite" :options="assiduites" class="form-select">
                        </c-select>
                        <label for="assiduite">Assiduité</label>
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select id="niveauSatisfaction" name="niveauSatisfaction" option-label="libelle" option-value="id" v-model="editableItem.niveauSatisfaction" :options="niveauSatisfactions" class="form-select">
                        </c-select>
                        <label for="niveauSatisfaction">Niveau de satisfaction</label>
                        <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div>Date de début</div>
                        <p-calendar
                          class="col-12 md:col-12"
                          :showIcon="true"
                          size="sm"
                          locale="fr"
                          name="dateDeb"
                          :manualInput="false"
                          v-model="editableItem.dateDebut"
                          :showWeek="true"
                          />  
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12 field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div>Date de fin</div>
                        <p-calendar
                          class="col-12 md:col-12"
                          :showIcon="true"
                          size="sm"
                          locale="fr"
                          name="dateFin"
                          :manualInput="false"
                          v-model="editableItem.dateFin"
                          :showWeek="true"
                          />  
                      <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                    </ValidationProvider>
                  </div>

                </div>
              </template>
            </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text"/>
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from '@/mixins/paginator-mixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    title: {
      type:  String,
      required: true
    },
    size: {
      type:  String,
      default: 'md'
    },
    item: {
      type:  Object,
      default: () => ({ })
    }
  },
  mixins: [paginatorMixin],
  data () {
    return {
      display: false,
      editableItem: {},
      filterOptions: [],
      error: false,
    }
  },
  watch: {
    item () {
      this.editableItem = { ...this.item }

    },
  },
  created () {
    this.fetchNiveauSatisfactions()
    this.fetchAssiduites()
  },
  computed: {
    ...mapGetters({
      assiduites: 'assiduite/assiduites',
      niveauSatisfactions: 'niveauSatisfaction/niveauSatisfactions',
    }),
    typeExprimeurs () {
      return [
        { value: 'CONSEILLLER', label: 'CONSEILLLER' },
        { value: 'DEMANDEUR', label: 'DEMANDEUR' },
        { value: 'ENTREPRISE', label: 'ENTREPRISE' },
      ]
    },
  },
  methods: {
    ...mapActions({
      fetchAssiduites: 'assiduite/fetchAssiduites',
      fetchNiveauSatisfactions: 'niveauSatisfaction/fetchNiveauSatisfactions',
    }),
    resetContent(){
      
    },
    show () {
      this.resetContent()
        this.display = true
    },
    hide () {
        this.display = false
    },
    ok () {
      this.editableItem = {}
    },
    cancel () {
      this.hide()
      this.$emit('canceled')
    },
    handleInput(e){
      this.editableItem[e.target.name] = e.target.value
      this.$emit('input', this.editableItem)
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async save(){
      if(!await this.isCompleted()) return;
      
      this.$emit('save', {
        ...this.editableItem,
        exprimePar: this.editableItem.exprimePar.value
      })
      this.editableItem = {}
      this.hide()
    },
  }
  
}
</script>